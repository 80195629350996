import { withTheme } from 'emotion-theming'
import React from 'react'
import BannerStyles from './Banner.styles'
import ChatStyles from './Chat.styles'
import GlobalCss from './GlobalCss.styles'
import MegaFormStyles from './MegaForm.styles'
import ModalStyles from './Modal.styles'
import PackageCardStyles from './PackageCard.styles'

const GlobalStyles = withTheme(() => (
  <>
    <GlobalCss />
    <ModalStyles />
    <MegaFormStyles />
    <PackageCardStyles />
    <BannerStyles />
    <ChatStyles />
  </>
))

export default GlobalStyles
